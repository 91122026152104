<template>
  <div class="space-y-32">
    <div>
      <div class="relative">
        <div class="absolute w-full z-10 pt-64">
          <Container>
            <div class="grid place-items-center">
              <div class="max-w-3xl text-center">
                <div class="space-y-6">
                  <h1
                    class="text-3xl md:text-[40px] md:leading-[49.1px] text-white"
                    v-html="$t('home_page_title')"
                  ></h1>
                  <div
                    class="text-lg leading-5 md:text-xl text-white font-general-sans"
                    v-html="$t('home_page_desc')"
                  ></div>
                  <iframe
                    title="surya adventure youtube"
                    srcdoc="
                          <style>
                              body, .full {
                                  width: 100%;
                                  height: 100%;
                                  margin: 0;
                                  position: absolute;
                                  display: flex;
                                  justify-content: center;
                                  object-fit: cover;
                              }
                          </style>
                          <a
                              href='https://www.youtube.com/embed/G4Dq7UTg4To?autoplay=1&enablejsapi=1&disablekb=1&controls=0&rel=0&iv_load_policy=3&cc_load_policy=0&playsinline=1&showinfo=0&modestbranding=1&fs=0&loop=1'
                              class='full'
                              onmouseover='this.click()'
                          >
                              <img
                                  src='https://vumbnail.com/G4Dq7UTg4To.jpg'
                                  class='full'
                              />
                              <svg
                                  version='1.1'
                                  viewBox='0 0 68 48'
                                  width='68px'
                                  style='position: relative;'
                              >
                                  <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                                  <path d='M 45,24 27,14 27,34' fill='#fff'></path>
                              </svg>
                          </a>
                      "
                    style="width: 100%; aspect-ratio: 16/9"
                  ></iframe>
                  <!-- <div class="w-full">
                    <div class="max-w-md mx-auto">
                      <HomeSearch />
                    </div>
                  </div> -->
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div class="-mt-20">
          <div class="relative">
            <div class="absolute h-full w-full bg-gradient-to-t from-black/50 to-black/50"></div>
            <NuxtImg
              src="/banner-home.jpg"
              alt="rafting atv bali"
              class="w-full h-[1000px] min-h-[580px] object-cover"
              format="webp"
              fetchPriority="high"
              sizes="100vw sm:50vw md:680px"
              height="480"
              quality="100"
              loading="lazy"
            />
          </div>
        </div>
      </div>
      <div class="mx-auto relative isolate z-10">
        <ActivitySlide class="-mt-20" />
      </div>
    </div>

    <div>
      <!-- <div class="relative">
        <div class="absolute w-full h-full z-10">
          <Container class="py-16 space-y-8">
            <div class="lg:text-[40px] lg:leading-[49px] text-3xl max-w-xl">
              {{ $t('home.association_title') }}
            </div>
            <nuxt-link
              class="btn bg-black text-white hover:bg-black/80 transition-all duration-300"
              no-prefetch
              to="/about"
              aria-label="Learn more"
            >
              {{ $t('learn_more') }}
            </nuxt-link>
          </Container>
        </div>
        <div class="opacity-10 xl:opacity-100">
          <div class="grid place-items-end w-full">
            <div>
              <NuxtImg class="h-full w-full max-h-[550px] mas" alt="backflip" src="/bike-ride.png" format="webp" />
            </div>
          </div>
        </div>
      </div> -->
      <div class="relative">
        <div class="w-full h-full absolute bg-gradient-to-t from-black/30 to-black/50">
          <Container class="h-full">
            <div class="grid place-items-center h-full">
              <div class="max-w-3xl text-center">
                <div class="space-y-6">
                  <h2 class="text-3xl md:text-[40px] md:leading-[49.1px] text-white">{{ $t('home_page_title_2') }}</h2>
                  <div class="text-lg leading-5 md:text-xl text-white font-general-sans">
                    {{ $t('sop_desc') }}
                  </div>
                  <div>
                    <NuxtLink
                      to="/activities"
                      class="btn normal-case font-normal bg-white"
                      aria-label="Explore activities"
                    >
                      {{ $t('explore_activities') }}
                    </NuxtLink>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </div>
        <div class="min-h-[450px]">
          <NuxtImg
            src="/home-middle.jpg"
            class="h-full w-full object-cover min-h-[450px]"
            alt="all activities"
            format="webp"
          />
        </div>
      </div>
    </div>

    <!-- <div>
      <Container>
        <video
          :autoplay="false"
          class="aspect-video w-full rounded-lg"
          controls
          poster="/cover-sb-1.jpg"
          preload="metadata"
        >
          <source :src="`/api/video/sb-vid.mp4`" type="video/mp4" />
        </video>
      </Container>
    </div> -->

    <!-- <div>
      <Container class="space-y-16">
        <div class="space-y-4 max-w-md">
          <h3 class="lg:text-[40px] lg:leading-[49px] text-3xl">
            {{ $t('home.popular_single_title') }}
          </h3>
          <p class="text-lg leading-5 md:text-xl">
            {{ $t('home.popular_single_description') }}
          </p>
        </div>
        <div class="space-y-4">
          <template v-for="(product, index) in productStore.otherProduct" :key="index">
            <PackageSlide :product="product" />
          </template>
        </div>
      </Container>
    </div> -->

    <!-- <div>
      <Container class="space-y-16">
        <div class="space-y-4 max-w-md">
          <h3 class="lg:text-[40px] lg:leading-[49px] text-3xl">
            {{ $t('home.combo_package_title') }}
          </h3>
          <p class="text-lg leading-5 md:text-xl">
            {{ $t('home.combo_package_description') }}
          </p>
        </div>
        <div class="grid grid-cols-1 lg:grid-cols-3 gap-8">
          <div v-for="(product, index) in productStore.comboProduct" :key="index" class="lg:even:-mt-10">
            <ComboCard :combo-product="product" />
          </div>
        </div>
        <div class="text-center">
          <NuxtLink
            to="/activities/combo"
            no-prefetch
            class="btn btn-neutral normal-case font-normal text-base"
            aria-label="See all activities"
          >
            {{ $t('see_all_combo_text') }}
          </NuxtLink>
        </div>
      </Container>
    </div> -->

    <div>
      <Container class="space-y-16 mb-24">
        <div class="space-y-4 max-w-lg">
          <h3 class="lg:text-[40px] lg:leading-[49px] text-3xl">
            {{ $t('home.real_story') }}
          </h3>
        </div>
        <div class="grid grid-cols-1 gap-8">
          <!-- <ReviewItem
            v-for="(review, index) in reviews"
            :key="index"
            :image="review.image"
            :customer-name="review.name"
            :rating="review.rating"
            :description="review.description"
          /> -->
          <template v-if="config.public.APP_STATUS == 'PRODUCTION'">
            <ClientOnly>
              <div class="elfsight-app-b55f9732-0a4f-4728-b97a-9e721449f353" data-elfsight-app-lazy></div>
            </ClientOnly>
          </template>
          <template v-if="config.public.APP_STATUS == 'DEV'">
            <ClientOnly>
              <div class="elfsight-app-749b4b4d-12f7-41c9-a9a0-5b5effa0f9a8" data-elfsight-app-lazy></div>
            </ClientOnly>
          </template>
        </div>
      </Container>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useProductStore } from '@/stores/product'
const { $swal } = useNuxtApp()

$swal.fire({
  title:
    '<span class="md:text-3xl text-xl">Our Website Just Launched!<br> <a class="underline" href="/activities">Order Now</a> to Get Special Offers Just for You!</span>',
  html: `<ul class='text-left'><li class="text-base">1. <span class='font-semibold'>30% Off</span> for All Our Activities Booked via our website</li><li class="text-base">2. <span class='font-semibold'>Free</span> Pick Up & Drop Off for All Bookings (Minimum Day -1)</li><li class="text-base">3. <span class='font-semibold'>Free</span> Full-Day Private Transport for Bookings Minimum 4 persons (Minimum Day -1)</li></ul>`,
  showCloseButton: true,
  showConfirmButton: false
})

const { href } = useCanonical()
const { t: $t } = useI18n()
const productStore = useProductStore()
// const { reviews } = useReviews()
const config = useRuntimeConfig()

onMounted(async () => {
  await nextTick()
  await productStore.loadProduct()
  await productStore.setProductCategory()
})

useSeoMeta({
  title: $t('meta.home.title'),
  description: $t('meta.home.description'),
  ogTitle: $t('meta.home.title'),
  twitterTitle: $t('meta.home.title'),
  ogDescription: $t('meta.home.description'),
  twitterDescription: $t('meta.home.description'),
  ogType: 'website',
  ogUrl: href,
  twitterCard: 'summary_large_image',
  keywords: 'Adventure, Bali, Surya Bintang, ATV, Rafting , Indonesia Travel ,Bike Cycle Ride'
})

useServerSeoMeta({
  title: $t('meta.home.title'),
  description: $t('meta.home.description'),
  ogTitle: $t('meta.home.title'),
  twitterTitle: $t('meta.home.title'),
  ogDescription: $t('meta.home.description'),
  twitterDescription: $t('meta.home.description'),
  ogType: 'website',
  ogUrl: href,
  twitterCard: 'summary_large_image',
  keywords: 'Adventure, Bali, Surya Bintang, ATV, Rafting, Indonesia Travel, Bike Cycle Ride'
})

const structuredData = ref({
  '@context': 'https://schema.org',
  '@type': 'WebPage',
  name: 'Surya Bintang Adventures',
  url: href,
  description: $t('meta.home.description'),
  author: {
    '@type': 'Organization',
    name: 'Surya Bintang Adventures'
  }
})

useHead({
  link: [
    {
      rel: 'canonical',
      href
    }
  ],
  // meta: [
  //   {
  //     name: 'description',
  //     content: $t('meta.home.description'),
  //     key: 'description'
  //   }
  // ],
  script: [
    {
      type: 'application/ld+json',
      children: JSON.stringify(structuredData.value)
    },
    {
      type: 'text/javascript',
      src: 'https://static.elfsight.com/platform/platform.js',
      async: true
    }
  ]
})
</script>

<style>
.swal2-title {
  line-height: 1 !important;
}
</style>
