<template>
  <div>
    <div class="fixed bottom-8 right-4 z-50">
      <NuxtLink
        to="https://wa.me/62818881388?text=Hi%20Surya%20Bintang%20Adventures%2C%20I%20came%20across%20your%20website%20and%20am%20interested%20in%20your%20activities.%20Can%20you%20help%20me%20book%3F"
        no-prefetch
        class="pt-2 text-center font-medium inline-block text-white cursor-pointer align-middle w-[200px] h-[40px] bg-cover bg-center bg-no-repeat bg-[url('/btn-green.png')]"
      >
        Help to Book
      </NuxtLink>
      <VMenu>
        <!-- <a class="text-sm" target="_blank" href="https://wa.me/+62818881388" rel="nofollow">
          <button type="button" class="bg-green-700 hover:bg-green-900 text-white py-2 px-4 rounded-full font-normal">
            {{ $t('customer_care_text') }}
          </button>
        </a> -->
        <!-- <template #popper>
          <ul class="menu">
            <li>
              <a class="text-sm" target="_blank" href="https://wa.me/+62818881388" rel="nofollow">
                <Icon name="i-logos-whatsapp-icon" />
                +62 818-881-388
              </a>
            </li>
            <li>
              <a class="text-sm" href="mailto:reservation@suryabintang.com" target="_blank">
                <Icon name="i-heroicons-envelope" />
                reservation@suryabintang.com
              </a>
            </li>
          </ul>
        </template> -->
      </VMenu>
    </div>
  </div>
</template>

<script setup lang="ts"></script>

<style scoped></style>
