export const appHead = {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"format-detection","content":"telephone=no"},{"name":"robots","content":"index, follow"},{"name":"author","content":"Surya Bintang Adventures"},{"property":"og:title","content":"Surya Bintang Adventures Bali"},{"property":"og:description","content":"Surya Bintang Adventures"},{"property":"og:type","content":"website"},{"property":"og:url","content":"https://suryabintangadventures.com"},{"property":"og:image","content":"https://suryabintangadventures.com/banner-social.jpg"},{"property":"og:site_name","content":"Surya Bintang Adventures"},{"name":"twitter:title","content":"Surya Bintang Adventures Bali"},{"name":"twitter:description","content":"Surya Bintang Adventures"},{"name":"twitter:card","content":"summary_large_image"},{"name":"twitter:image","content":"https://suryabintangadventures.com/banner-social.jpg"}],"link":[{"rel":"icon","type":"image/x-icon","href":"/favicon.jpg"}],"style":[],"script":[{"hid":"gtm","children":"(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-NWB4D42P');","type":"text/javascript"}],"noscript":[],"titleTemplate":"%s - Surya Bintang Adventures Bali","htmlAttrs":{"lang":"en"}}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appLayoutTransition = false

export const appPageTransition = false

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appRootAttrs = {"id":"__nuxt"}

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const appTeleportAttrs = {"id":"teleports"}

export const renderJsonPayloads = true

export const componentIslands = false

export const payloadExtraction = true

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = true

export const nuxtLinkDefaults = {"componentName":"NuxtLink","prefetch":true,"prefetchOn":{"visibility":true}}

export const asyncDataDefaults = {"value":null,"errorValue":null,"deep":true}

export const resetAsyncDataToUndefined = true

export const nuxtDefaultErrorValue = null

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false

export const appId = "nuxt-app"

export const outdatedBuildInterval = 3600000

export const multiApp = false